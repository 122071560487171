<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
  // props: {
  //   currentPath: {
  //     type: String,
  //     default: ''
  //   }
  // },
  setup(props) {
    let route = useRoute()
    let stepList = [{
      name:'1.实名认证', 
      matchPath: '/verified' 
    }, {
      name: '2.签署协议', 
      matchPath: '/trust'
    }, {
      name: '3.开通服务',
      matchPath: '/open-service'
    }]

    return {
      stepList
    }
  }
}
</script>
<template>
  <div class="channel-step-bar">
    <div class="bar-fixed">
      <ul>
        <li 
          v-for="item in stepList" 
          :style="{color: $route.path.indexOf(item.matchPath) >=0 ? '#00ABEB' : ''}" 
          :key="item">
          {{item.name}}
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped lang="less">
    .channel-step-bar {
      position: relative;
      z-index: 2000;
      height:1.1rem;
      background: #fff;
      ul {
        display: table;
        width: 100%;
        height:100%;
        table-layout: fixed;
        li {
          display: table-cell;
          text-align:center;
          vertical-align: middle;
          font-size:.3rem
        }
      }
      .bar-fixed {
        position: fixed;
        top: 0;
        width: 100%;
        height:inherit;
        background:#fff
      }
    }
</style>