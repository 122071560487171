<script>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue'
import { upload } from 'qiniu-js'
import { uuid } from '@/utils'
import { http } from '@/http'
import { Toast } from 'vant'
import { useRoute } from 'vue-router'
import router from '@/routes'
import channelStepBar from '@/components/channel-step-bar'
import { needLoading } from '@/reactive'
import { idcardOcr, addCardInfo } from '@/http/api'
import qiniuUpload from '@/utils/qiniu-upload'

export default {
  components: {
    channelStepBar
  },
  setup() {
    let realName = ref('')
    let cardNum = ref('')
    let sex = ref('')
    let validateDate = ref('')
    let address = ref('')
    let cardFront = ref('')
    let cardBack = ref('')
    let initReceive = ref(false)
    let imageBack = ref('')
    let imageFront = ref('')
    let uploadFrontDone = ref(true)
    let uploadBackDone = ref(true)

    let frame = ref(0)
    let dotsLimit = 3
    let dotsText = ref('')
    let dotsTimer = null

    let route = useRoute()

    let realSex = computed({
      get() {
        if(sex.value === 'M') {
          return '男'
        }
        if(sex.value === 'F') {
          return '女'
        }
        return sex.value
      },
      set(value) {
        return sex.value = value
      }
      
    })

    ;(function loadingDots() {
      frame.value += 1
      let num = frame.value % (dotsLimit + 1)
      dotsText.value = num ? dotsText.value+'.' : ''      
      dotsTimer = setTimeout(loadingDots, 500)
    })()
    
    onUnmounted(() => dotsTimer = null)
    
    function afterRead(type = 'front') {   
      return ({ file }) => {
        initReceive.value = false
        dotsText.value = ''
        if(type === 'front') {
          imageFront.value = false
          uploadFrontDone.value = false
        }
        else {
          imageBack.value = false
          uploadBackDone.value = false
        }
        qiniuUpload(file, {
          compress: true,
          errorCallback() {
            Toast.fail('上传文件失败，请重新上传！')
            uploadBackDone.value = true
            uploadFrontDone.value = true
          },
          completeCallback(res) {
            if(type === 'front') {
              cardFront.value = res.result
              uploadFrontDone.value = true
            }
            else {
              cardBack.value = res.result
              uploadBackDone.value = true
            }
          }
        })
      }      
    }

    function receiveRealName() {
      initReceive.value = true
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getTaxCommissionRecord')
        .then(res => {
          if(res.result) {
            cardFront.value = res.result.cardFront
            cardBack.value = res.result.cardBack
            cardNum.value = res.result.cardNum
            realName.value = res.result.realName
            sex.value = res.result.sex
            address.value = res.result.homeAddr
            validateDate.value = res.result.validateDate
          }
        })
    }


    function cardOcrMethod(imageUrl, idCardSide) {
      if(route.path !== '/verified' && initReceive.value) {
        return false
      }
      idcardOcr({
        idCardSide,
        imageUrl
      }).then(res => {
        if(res.result && res.result.isIdCard) {
          Toast.success('身份证识别成功！')
          if(idCardSide === 'front') {
            sex.value = res.result.sex
            realName.value = res.result.realName 
            cardNum.value = res.result.cardNum
            address.value = res.result.address
          }
          else {
            validateDate.value = res.result.validateDate
          }
        }
        else {
          Toast.fail('身份证识别失败!')
        }
        
      })
      needLoading.value = true
    }

    onMounted(() => {
      if(route.path !== '/verified'){
        receiveRealName()
      }
    })
    

    let frontImage = new Image()
    let backImage = new Image()
    
    frontImage.onload = function() {
      //Toast.success('身份证正面上传成功！')
      imageFront.value = true
      
    }

    backImage.onload = function() {
      //Toast.success('身份证反面上传成功！')
      imageBack.value = true
      
    }

    watch([cardFront, imageFront], (
      [front, imageFront], 
      [prevFront, prevImageFront]
    ) => {
      front !== prevFront && (frontImage.src = cardFront.value)
      if(front && imageFront) {
        cardOcrMethod(front, 'front')
      }
      
    })
    watch([cardBack,imageBack], (
      [ back, imageBack], 
      [prevBack, prevImageBack]
    ) => {
      back !== prevBack && (backImage.src = cardBack.value)
      if(back && imageBack) {
        cardOcrMethod(back, 'back')
      }
    })

    function onSubmit(fields) {
      addCardInfo({
        //...fields,
        cardNum: cardNum.value,
        realName: realName.value,
        cardType: 'IDCARD',
        sex: sex.value,
        cardFront: cardFront.value,
        cardBack: cardBack.value,
        homeAddr: address.value,
        validateDate: validateDate.value
      }).then(res => {
        if(res.errCode === 0) {
          if(route.path === '/verified') {
            router.push('/trust?type=broker')
          }
          else {
            router.push('/trust')
          }
          
        }
        else {
        }
      })
    }

    // function onOversize(file) {
    //   alert('文件大小不能超过5M')
    // }
 
    //onMounted(receiveRealName)
    return {
      afterRead,
      onSubmit,
      cardNum,
      realName,
      cardFront,
      cardBack,
      validateDate,
      sex,
      address,
      // onOversize,
      uploadFrontDone,
      uploadBackDone,
      dotsText,
      realSex,
    }
  }
}
</script>
<template>
  <div class="page">
    <!--     
    <div class="page-title">
      <h3>实名认证</h3>
    </div> -->
    <channel-step-bar v-if="$route.path==='/verified'" />
    <p style="padding:.28rem .3rem; font-size:.24rem; color:#666">请拍摄或上传以下证件照信息，注意避免证件反光</p>
    <van-form @submit="onSubmit">
      <div class="box">
        <h4>上传身份证照</h4>
        <div style="display:table; width:100%">
          <div style="display:table-cell; width:40%;position: relative;  text-align:left">
            <van-uploader upload-text="上传身份证正面" accept="image/*" :max-count="1" :multiple="false" :after-read="afterRead()">
              <div class="card-bg card-front-bg" ></div>
              <div class="image-cover">
                <img v-if="cardFront" :src="cardFront" />
              </div>
              <span v-if="!uploadFrontDone" class="upload-loading">上传中{{dotsText}}</span>
              <span class="icon-camera"><img src="@/assets/images/icon-camera.png" /></span>
            </van-uploader>
            <p style="margin:10px 0">点击上传带头像一面</p>
          </div>
          <div style="display:table-cell; width:40%; position: relative; text-align:right">
            <van-uploader upload-text="上传身份证反面" accept="image/*" :max-count="1" :multiple="false" :after-read="afterRead('back')">
              <div class="card-bg card-back-bg" ></div>
              <div class="image-cover">
                <img v-if="cardBack" :src="cardBack" />
                <span v-if="!uploadBackDone" class="upload-loading">上传中{{dotsText}}</span>
              </div>
              <span class="icon-camera"><img src="@/assets/images/icon-camera.png" /></span>
            </van-uploader>
            <p style="margin:10px 0">点击上传带国徽一面</p>
          </div>
        </div>
      </div>
      <div class="box">
        <h4>基本信息</h4>
        <van-field 
          name="realName"
          v-model="realName" 
          required 
          label="真实姓名" 
          autocomplete="off"
          placeholder="请输入真实姓名" 
        />
        <van-field 
          name="cardNum" 
          required 
          v-model="cardNum" 
          autocomplete="off"
          label="身份证号" 
          placeholder="请输入身份证号码" 
        />
        <van-field 
          name="address" 
          required 
          autocomplete="off"
          v-model="address" 
          label="地址" 
          placeholder="请输入地址" 
        />
        <!-- <van-field 
          readonly
          name="realSex" 
          required 
          v-model="realSex" 
          autocomplete="off"
          label="性别" 
          maxlength="1"
          placeholder="请输入性别" 
        /> -->
        <van-field 
          name="cardNum" 
          required 
          autocomplete="off"
          v-model="validateDate" 
          label="有效期" 
          placeholder="请输入证件有效期" 
        />
        
      
      </div>
      <div class="box" >
        <div style="margin: 20px 0 0;">
          <van-button block type="primary" native-type="submit">提交</van-button>
        </div>
      </div>
    </van-form>
    
  </div>
</template>
<style scoped lang="less">
    h4 {font-size: .3rem; text-align:left; font-weight: 700; margin: .3rem 0}
    .box { overflow: hidden;font-size: .28rem; padding: 0 .4rem; margin-bottom: .3rem; background-color: #fff;}
    .box li { border-bottom: 1px solid #eee; height:.85rem; display:table; width:100% }
    .box li:last-child { border-bottom:none }
    .box li span { display: table-cell;  vertical-align: middle; width:2.3rem }
    .box li input { width: 100%; border:none }
    .box li div { display: table-cell;  vertical-align: middle}
    .box:last-child {  background-color:transparent }
    .card-bg{height:2rem;width:100%}
    .image-cover { position:absolute; top: 0; left:0; height:2rem;width:100% }
    .image-cover img {width: 100%; height:100% }
    .card-front-bg { background-size: 100% 100%; background-image:url('~@/assets/images/card-front.png') }
    .card-back-bg { background-size: 100% 100%;background-image:url('~@/assets/images/card-back.png') }
    .icon-camera { position: absolute; top: 50%; left: 50%; margin-top: -.335rem; margin-left: -.335rem; width:.67rem; height:.67rem }
    .upload-loading {position:absolute; top: 0; left:0; line-height: 2rem; text-align: center; height:2rem;width:100%; z-index: 1000;background: rgba(0,0,0,.4); color: #fff}
    /deep/ .van-cell { padding-left:0; padding-right:0;
      
     }
    /deep/ .van-cell::after {
        left: 0;
        right: 0;
        border-bottom: 1px solid #f4f5f9;
      }
    /*.van-uploader__input-wrapper { width:100% }*/
    /deep/ .van-uploader {
      width: 93%;
    }
    /deep/ .van-uploader__wrapper {
      display: block;
    }
    /*/deep/ .van-uploader__upload {
      width: 100%;
    }*/
    
</style>